<template>
  <div>
    <md-result-page type="lost" subtext="页面不存在"></md-result-page>
  </div>
</template>

<script>
  import { ResultPage } from 'mand-mobile'
	export default {
    components: {[ResultPage.name]: ResultPage}
	}
</script>